'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.11 Download de NFSe Individual

Obtém o XML de um NFSe específico.

#### Requisição a enviar

Para obter o XML de um NFSe

    GET /api/v1/nfse/{id}/download

#### Parâmetros

Parâmetro     | Conteúdo
------------- | -------------
\`id\`        | ID da NFSe desejada.

#### Respostas possíveis

##### 200: dados da NFSe

Retorna o XML da NFSe:

\`\`\`
<NFe xmlns="">
  <Assinatura> ... </Assinatura>
  <ChaveNFe> ... </ChaveNFe>
  <DataEmissaoNFe> ... </DataEmissaoNFe>
  ...
</NFe>
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita.

\`\`\`
{ "error": "Requisição inválida." }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Banco de dados fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -X GET 'https://portal.consyste.com.br/api/v1/nfse/62b080477bbe81e57e06b5bc/download'
\`\`\``;

export default function DownloadNfse() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}
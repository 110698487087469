'use strict';

import React from 'react';
import Markdown from '../../../common/markdown';

const texto = `## 3 Operações

### 3.10 Lista de NFSe

<p style="margin-left: 5px;">Obtém uma lista com dados das NFSes constantes no Portal.</p>

#### 3.10.1 Busca Inicial

<div style="text-align: justify; margin-left: 5px;">
  É a busca realizada no primeiro momento. O retorno dessa busca corresponde à primeira página dos resultados. Caso existam mais registros do que os disponíveis na primeira página, o cliente deve solicitar a continuação da busca, utilizando o código de paginação que é retornado.
</div>

#### Requisição a enviar

* Para iniciar uma busca completa:

  <b>GET /api/v1/nfse/lista</b>
  
  Exemplo:
      
      GET /api/v1/nfse/lista

* Para iniciar uma busca filtrada:

  <b>GET /api/v1/nfse/lista?q={q}</b>
  
  Exemplo:
      
      GET /api/v1/nfse/lista?q=numero%3A%20%5B908705%20TO%20908705%5D

* Para retornar campos específicos:

  <b>GET /api/v1/nfse/lista?campos={campos}</b>
  
  Exemplo:
    
      GET /api/v1/nfse/lista?campos=numero,codigo_verificacao,chave
      
* Para iniciar uma busca filtrada com campos específicos:

  <b>GET /api/v1/nfse/lista?q={q}&campos={campos}</b>
  
  Exemplo:
    
      GET /api/v1/nfse/lista?q=numero%3A%20%5B908705%20TO%20908705%5D&campos=numero,codigo_verificacao,chave

#### Parâmetros

Parâmetro           | Conteúdo
------------------- | -------------
\`q\`               | para lista inteira: vazio <br/> para lista filtrada: string de busca
\`campos\`          | lista de campos a retornar, separados por vírgula. caso não seja fornecido, \
                      serão considerados os seguintes campos:<br/> \
                      \`id\`, \`municipio\`, \`emitido_em\`, \`numero\`, \`codigo_verificacao\`, \`chave\`, \`prestador_cpf_cnpj\`, \`prestador_razao_social\`, \
                      \`status_sefaz\`, \`valor\`, \`tomador_cpf_cnpj\`, \`tomador_razao_social\`, \`discriminacao\`.<br/>\

#### Respostas possíveis

##### 200: lista de NFSe

Retorna a quantidade total de NFSes da busca, o código para a próxima página, e a lista de documentos.

\`\`\`
{
  "total": 3,
  "proxima_pagina": "c2NhbjswOzE7dG90YWxfaGl0czozOw==",
  "documentos": [
    { 
      "id": "6297d71e7bbe81341f24ba9a",
      "municipio": 3550308,
      "emitido_em": "2014-04-08T00:00:00-03:00",
      "numero": 908705,
      "codigo_verificacao": "TMDYGEVJ",
      "chave": "31062001214422279000106230000000328823015997666960",
      "prestador_cpf_cnpj": "43283811001040",
      "prestador_razao_social": "Kalunga Comercio Industria Grafica Ltda",
      "status_sefaz": "N",
      "valor": "10.5"
      "tomador_cpf_cnpj": "10967573000142",
      "tomador_razao_social": "MEp Consultoria",
      "discriminacao": "ALIQUOTAS DOS TRIBUTOS INCIDENTES",
    },
    { ... },
    { ... }
  ]
}
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "A string de busca informada é inválida" }
\`\`\`

##### 403: ação não permitida

Significa que sua chave de acesso não tem permissão para listar documentos.

\`\`\`
{ "error": "Ação não permitida" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Serviço de busca fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X GET 'https://portal.consyste.com.br/api/v1/nfse/lista'
\`\`\``;

export default function ListaNfse() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}

'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.10 Lista de Documentos

<p style="margin-left: 5px;">Obtém uma lista com dados das NFSes constantes no Portal.</p>

#### 3.10.2 Continuação de uma busca por NFSe

<div style="text-align: justify; margin-left: 5px;">
  A continuação de uma busca corresponde às páginas seguintes da busca básica. A continuação é acessada somente através do código que a API envia como retorno na busca básica. Para cada página de retorno é necessário enviar o código da paginação, que foi obtido na página anterior.
</div> 

#### Requisição a enviar

* Para realizar a continuação de uma busca por NFSe:

  <b>GET /api/v1/nfse/lista/continua/{codigo_paginacao}</b>

  Exemplo:
      
      GET /api/v1/nfse/lista/continua/H4sIAGHXb2IAA9PV1eXSVbAqtFJQzy==

#### Parâmetros

Parâmetro           | Conteúdo
------------------- | -------------
\`codigo_paginacao\`| na primeira requisição: não informar <br/> nas demais: o código recebido na requisição anterior

#### Respostas possíveis

##### 200: lista de NFSe

Retorna a quantidade total de NFSes da busca, o código para a próxima página, e a lista de documentos.

\`\`\`
{
  "total": 3,
  "proxima_pagina": "c2NhbjswOzE7dG90YWxfaGl0czozOw==",
  "documentos": [
    { 
      "id": "6297d71e7bbe81341f24ba9a",
      "municipio": 3550308,
      "emitido_em": "2014-04-08T00:00:00-03:00",
      "numero": 908705,
      "codigo_verificacao": "TMDYGEVJ",
      "chave": "31062001214422279000106230000000328823015997666960",
      "prestador_cpf_cnpj": "43283811001040",
      "prestador_razao_social": "Kalunga Comercio Industria Grafica Ltda",
      "status_sefaz": "N",
      "valor": "10.5"
      "tomador_cpf_cnpj": "10967573000142",
      "tomador_razao_social": "MEp Consultoria",
      "discriminacao": "ALIQUOTAS DOS TRIBUTOS INCIDENTES",
    },
    { ... },
    { ... }
  ]
}
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "A string de busca informada é inválida" }
\`\`\`

##### 403: ação não permitida

Significa que sua chave de acesso não tem permissão para listar documentos.

\`\`\`
{ "error": "Ação não permitida" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Serviço de busca fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X GET 'https://portal.consyste.com.br/api/v1/nfse/lista'
\`\`\``;

export default function ContinuacaoBuscaNfse() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}

'use strict';

import * as React from 'react';

import { Router, Route, IndexRedirect, browserHistory, applyRouterMiddleware, Redirect } from 'react-router';
import { useScroll } from 'react-router-scroll'

import DocumentacaoApp from './documentacao_app';
import Introducao from './paginas/introducao';
import EnvioDocumentos from './paginas/envio_documentos';
import BuscaInicial from './paginas/lista_documentos/busca_inicial';
import ContinuacaoBusca from './paginas/lista_documentos/continuacao_busca';
import ConsultaDocumentos from './paginas/consulta_documentos';
import DownloadDocumentos from './paginas/download_documentos';
import SolicitacaoDownload from './paginas/solicitacao_download';
import ConsultaDownload from './paginas/consulta_download';
import ManifestacaoDestinatario from './paginas/manifestacao_destinatario';
import DecisaoPortaria from './paginas/decisao_portaria';
import EmissaoNfe from './paginas/emissao_nfe';
import ConsultaEmissaoNfe from './paginas/consulta_emissao_nfe';
import ListaNfse from './paginas/lista_nfses/lista_nfse';
import ContinuacaoBuscaNfse from './paginas/lista_nfses/continuacao_busca_nfse';
import DownloadNfse from './paginas/download_nfse';

const routes = (
    <Route path="/doc/api" component={DocumentacaoApp}>
      <IndexRedirect to="introducao" />
      <Route title="Introdução" path="introducao" component={Introducao}/>
      <Route title="Envio de Documentos" path="envio-documentos" component={EnvioDocumentos}/>
      <Route title="Lista de Documentos">
        <Redirect from="lista-documentos" to="busca-inicial"/>
        <Route title="Busca Inicial" path="busca-inicial" component={BuscaInicial}/>
        <Route title="Continuação de uma busca" path="continuacao-busca" component={ContinuacaoBusca}/>
      </Route>
      <Route title="Consulta a Documentos" path="consulta-documentos" component={ConsultaDocumentos}/>
      <Route title="Download de Documento" path="download-documentos" component={DownloadDocumentos}/>
      <Route title="Solicitação de Download de Documentos" path="solicitacao-download" component={SolicitacaoDownload}/>
      <Route title="Consulta de Download Solicitado" path="consulta-download" component={ConsultaDownload}/>
      <Route title="Manifestação do destinatário de NF-e" path="manifestacao-destinatario" component={ManifestacaoDestinatario}/>
      <Route title="Decisão Portaria de NF-e" path="decisao-portaria" component={DecisaoPortaria}/>
      <Route title="Emissão de NF-e">
        <Route title="Envio de dados para emissão de NFe" path="emissao-nfe" component={EmissaoNfe}/>
        <Route title="Consultar Solicitação de Emissão" path="consulta-emissao-nfe" component={ConsultaEmissaoNfe}/>
      </Route>
      <Route title="Lista de NFSe">
        <Redirect from="lista-nfse" to="busca-inicial-nfse"/>
        <Route title="Busca Inicial" path="busca-inicial-nfse" component={ListaNfse}/>
        <Route title="Continuação de uma busca" path="continuacao-busca-nfse" component={ContinuacaoBuscaNfse}/>
      </Route>
      <Route title="Download de NFSe individual" path="download-nfse" component={DownloadNfse}/>
    </Route>
);

export default function Documentacao() {
  return <Router history={browserHistory}
                 render={applyRouterMiddleware(useScroll())}
                 routes={routes}/>;
}
